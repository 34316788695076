<template>
    <div id="TS" :style="{ backgroundImage: 'url(' + globalConfig.RESOURCE_PREFIX + '/MBL_E-handbook_Design/2nd_layer/2nd_layer_TS.jpg)' }">
      <a id="blow" href="javascript:void(0);" @click.stop="redirectTo('SystemsDetail','blow')"></a>
      <a id="compress" href="javascript:void(0);" @click.stop="redirectTo('SystemsDetail','compress')"></a>
      <a id="cooldown" href="javascript:void(0);" @click.stop="redirectTo('SystemsDetail','cooldown')"></a>
      <a id="pump" href="javascript:void(0);" @click.stop="redirectTo('SystemsDetail','pump')"></a>
      <div>
        <span style="
      position: absolute;
      bottom: 0px;
      font-size: 12px;
      left: 3px;
  ">沪ICP备10002431号
      </span>
      <span style="
          bottom: 0px;
          position: absolute;
          right: 3px;
          font-size: 12px;
      ">沪公网安备31010502000766号
      </span>
    </div>
    </div>
  </template>
  
  
<script>
  
export default {
  name: "ThermalSystems",
  data: () => ({
    loading: false,
    items: [
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me' }
    ],
    offset: true,
  }),
  mounted(){
  },
  computed: {
  },
  methods: {
    redirectTo(path,subSystem) {
      this.$router.push({
        path,
        query: {
          subSysname: subSystem
        },
      });
    },
  },
};
</script>
  
<style lang="sass">
.home
  height: calc(100% - 0px)
  
#TS
  position: relative
  height: 100%
  background-position: 0px 0px !important
  background-size:100%  110%
  max-width: 100% !important
h4
  font-size: 20px
  line-height: 1.25
  font-family: BoschSans-Light,Helvetica,Arial,sans-serif
  color: #005691
  font-style: normal
p
  font-size: 16px !important
  line-height: 1.5 !important
  font-family: BoschSans-Light,Helvetica,Arial,sans-serif !important
#blow
  position: absolute
  left: 54%
  top: calc(29% - 0px)
  width: 30%
  height: 3.5%
  z-index: 999999999 !important
#compress
  position: absolute
  left: 11%
  top: calc(35% - 0px)
  width: 30%
  height: 3.5%
  z-index: 999999999 !important
#cooldown
  position: absolute
  left: 7%
  top: calc(92% - 0px)
  width: 34%
  height: 3.5%
  z-index: 999999999 !important
#pump
  position: absolute
  left: 62%
  top: calc(92% - 0px)
  width: 25%
  height: 3.5%
  z-index: 999999999 !important
</style>